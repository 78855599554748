<template>
  <div v-if="tickets.length > 0">
    <div v-for="ticket in formattedTickets" :key="ticket.id">
      <ticket-card
        class="ticket-item mb-1"
        :id="ticket.id"
        :type="ticket.title"
        :title="ticket.subtitle"
        :price="ticket.price"
        :currency="ticket.currency"
      ></ticket-card>
    </div>
    <!--    <b-loading :active="isDownloading" :is-full-page="false" class="is-small"></b-loading>-->
  </div>
  <div v-else>
    <empty-ticket-list-view></empty-ticket-list-view>
  </div>
</template>

<script>
import TicketCard from "@/web/components/tickets/TicketCard";
import EmptyTicketListView from "@/web/components/tickets/EmptyTicketListView";
import { mapState } from "vuex";

export default {
  name: "AgendaSessionTicketListView",

  props: ["tickets"],

  data() {
    return {
      isDownloading: false,
    };
  },

  components: { EmptyTicketListView, TicketCard },

  computed: {
    ...mapState(["settings"]),

    formattedTickets() {
      return this.tickets.map(ticket => {
        return {
          id: ticket.id,
          title: ticket.agenda_session_display_data.name,
          subtitle: ticket.agenda_session_ticket.name || this.getCustomTicketName(ticket.agenda_session_ticket.id),
          price: ticket.agenda_session_ticket.price * 0.01,
          currency: ticket.agenda_session_ticket.currency,
        };
      });
    },
  },

  methods: {
    getCustomTicketName(id) {
      if (this.settings.ticket_types) {
        return this.$t(this.settings.ticket_types[id]);
      }
    },
  },
};
</script>

<style scoped></style>
