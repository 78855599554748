<template>
  <div class="container mt-4">
    <separator class="separator is-primary"></separator>
    <div class="has-text-centered title">
      {{ $t("edit_profile.edit_my_profile") }}
    </div>
    <edit-profile-form
      style="width: auto"
      class="mt-2"
      ref="form"
      :error="error"
      :value="defaultValues"
      @submit="updateUserProfile"
    ></edit-profile-form>
    <b-loading :active="showLoading" :is-full-page="false" class="is-small"></b-loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditProfileForm from "@/web/components/forms/EditProfileForm";
import Separator from "@/web/components/shared/Separator";
import Constants from "@/web/constants";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";

export default {
  name: "EditProfile",

  mixins: [AuthorizedRouteMixin],

  components: { EditProfileForm, Separator },

  data() {
    return {
      error: null,
      showLoading: false,
    };
  },

  computed: {
    ...mapGetters("currentUser", ["currentUser"]),

    defaultValues() {
      return this.mapUserToForm(this.currentUser);
    },
  },

  methods: {
    ...mapActions("currentUser", ["updateUser"]),

    updateUserProfile(form) {
      this.showLoading = true;
      this.updateUser(this.mapFormToUser(form))
        .then(result => this.onSuccess())
        .catch(error => (this.error = error))
        .finally(() => (this.showLoading = false));
    },

    onSuccess() {
      this.$router.replace({ name: Constants.ROUTE_MY_PROFILE });
    },

    mapUserToForm(user) {
      return {
        firstName: user.first_name,
        lastName: user.last_name,
        position: user.position,
        company: user.company,
        country: user.country ? user.country : "",
        biography: user.biography,
        phoneNumber: user.phone_number,
        whatIOffer: user.what_i_offer,
        whatINeed: user.what_i_need,
        showContactDetails: user.show_contact_details,
        facebookUrl: user.facebook_profile,
        twitterUrl: user.twitter_profile,
        linkedInUrl: user.linked_profile,
        youtubeUrl: user.youtube_profile,
        instagramUrl: user.instagram_profile,
      };
    },
    mapFormToUser(form) {
      return {
        first_name: form.firstName,
        last_name: form.lastName,
        position: form.position,
        company: form.company,
        country: form.country ? form.country : null,
        biography: form.biography,
        phone_number: form.phoneNumber,
        what_i_offer: form.whatIOffer,
        what_i_need: form.whatINeed,
        show_contact_details: form.showContactDetails,
        facebook_profile: form.facebookUrl,
        twitter_profile: form.twitterUrl,
        linked_profile: form.linkedInUrl,
        youtube_profile: form.youtubeUrl,
        instagram_profile: form.instagramUrl,
      };
    },
  },
  watch: {
    currentUser(value) {
      if (!value) {
        this.$router.replace({ name: Constants.ROUTE_MAIN_INTRODUCTION });
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 720px !important;
}
</style>
