<template>
  <user-availability></user-availability>
</template>

<script>
import UserAvailability from "@/web/components/availability/UserAvailability";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";

export default {
  name: "Availability",

  components: { UserAvailability },

  mixins: [AuthorizedRouteMixin],
};
</script>

<style scoped></style>
